<template>
  <div>
    <div v-if="show_file" class="mtopic">
      <div
        style="
          margin: 0 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 85%;
          white-space: nowrap;
        "
      >
        {{ topic }}
      </div>
      <language class="language-option"></language>
    </div>

    <el-radio-group
      v-if="show_file"
      v-model="e_button"
      size="mini"
      fill="rgb(0,76,170)"
      class="change_button"
    >
      <el-radio-button label="pubMindmap">
        {{ $t("share.map1") }}</el-radio-button
      >
      <el-radio-button label="pubWordview" fill="">{{
        $t("share.word1")
      }}</el-radio-button>
    </el-radio-group>

    <div v-show="show_map">
      <div v-if="show_file">
        <!-- myDiv 计算文本宽度高度 -->
        <div id="myDiv"></div>
        <!-- myCanvas 也来计算文本宽度高度 -->
        <canvas id="myCanvas" style="display: none"></canvas>
        <div
          class="body"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          <div :style="'display: flex;height:' + hei">
            <div
              id="container"
              style="height: 100%; width: 100%; flex: 1"
            ></div>
          </div>
        </div>

        <div
          id="minicontainer"
          style="position: fixed; z-index: 99; bottom: 45px; right: 0"
        ></div>
      </div>
    </div>
    <div
      id="woc"
      style="
        position: absolute;
        bottom: 0px;
        right: 0px;
        cursor: pointer;
        display: flex;
        height: 40px;
      "
      @click="to_web"
    >
      <div style="font-size: 16px">
        <el-button size="mini" type="text" color="red"
          ><i
            class="iconfont icon-a-fenzu21"
            style="margin-right: 10px; color: rgb(0, 76, 170)"
          ></i
        ></el-button>
      </div>
    </div>
    <div v-if="!show_map" :style="'display: flex;height:' + hei">
      <mword></mword>
    </div>
    <!-- <div id="minimapContainer" style="height: 100px; width: 100px" style='position: fixed;z-index: 99;bottom: 40px;'></div> -->

    <div v-if="show_file && e_button == 'pubMindmap'" class="save_button">
      <el-button
        v-if="!is_login"
        type="primary"
        style="text-align: center"
        size="mini"
        @click="to_down"
        >{{ $t("share.share_down") }}</el-button
      >
      <el-button
        v-else
        type="primary"
        style="text-align: center"
        size="mini"
        @click="to_login"
        >{{ $t("share.save") }}</el-button
      >
    </div>

    <div v-if="!show_file" class="nofile">
      <div>
        <p>{{ $t("share.s1") }}</p>
        <el-button type="primary" size="small" @click="notice">{{
          $t("share.s2")
        }}</el-button>
      </div>
    </div>
    <!-- 是否已有账户 -->
    <el-dialog
      :title="$t('share.share_tip')"
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="moveDialogVisible"
    >
      <div style="font-size: 16px; text-align: start">
        {{ $t("addMindMap.saveMindmapMoveDialogTitle") }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="hasAccount">{{
          $t("addLogin.loginEmail.login")
        }}</el-button>
        <el-button type="primary" size="small" @click="noAccount">{{
          $t("addMindMap.noAccount")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 保存  -->
    <el-dialog
      :title="$t('share.save')"
      width="80%"
      top="30vh"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="saveDialogVisible"
      :before-close="close_dai"
    >
      <el-select
        v-model="select_project"
        :placeholder="$t('share.pro')"
        style="width: 100%; margin-bottom: 25px"
      >
        <el-option
          v-for="item in project_options"
          :key="item.projectId"
          :label="item.projectName"
          :value="item.projectId"
        >
        </el-option>
      </el-select>

      <el-select
        v-if="select_project.length > 0"
        v-model="select_file"
        :placeholder="$t('share.file')"
        style="width: 100%; margin-bottom: 25px"
      >
        <el-option
          v-for="item in file_options"
          :key="item.fileTypeId"
          :label="item.name"
          :value="item.fileTypeId"
        >
        </el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="close_dai">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" size="small" @click="save_file">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 3天弹窗 -->
    <el-dialog
      :title="$t('share.ti')"
      width="80%"
      top="35vh"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="tipDialogVisible"
    >
      <div style="font-size: 16px; text-align: start">
        {{ tip_text }}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="tipDialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" size="small" @click="to_login">{{
          $t("btn.saveBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- loading -->
    <div v-show="show_process" id="loading_share">
      <img class="topbar-logo" :src="logoUrl" style="width: 15%" />
      <el-progress
        style="width: 50%; margin-top: 50px"
        :percentage="percent"
        :color="customColorMethod"
        :stroke-width="15"
        :show-text="false"
      ></el-progress>
      <div style="width: 50%; margin-top: 50px; font-size: 20px">
        loading: {{ parseInt(percent) }} %
      </div>
    </div>

    <!-- QQ  wx 打开 -->
    <el-dialog
      :title="$t('share.share_tip')"
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="qqDialogVisible"
    >
      <div style="font-size: 16px; text-align: start">
        {{ $t("share.share_open") }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="qqDialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getFile } from "@/network/home/index.js";
import get_water from "@/store/water.js";
import { removeToken } from "@/utils/auth";
import html2Canvas from "html2canvas";
import { Graph, DataUri } from "@antv/x6";
import Hierarchy from "@antv/hierarchy";
import { getToken } from "@/utils/auth";
import vmson from "@/utils/vmson";
import language from "@/components/language";
import { request_joinedProjects } from "@/network/personal/index.js";
import logo_light from "@/assets/logo_small.png";
import { get_filetype_List } from "@/network/fileType/index.js";
import exportPng from "@/components/exportPng/index.vue";
import {
  getFile,
  save_file,
  get_status,
  viewRequestNotify,
} from "@/network/share/index.js";
import mword from "@/views/pubWordview/mindex.vue";

export default {
  components: {
    language,
    mword,
    exportPng,
  },
  data() {
    return {
      show_top: true,
      max_y: 0,
      max_h: 0,
      show_file: true,
      hei: "",
      topic: "",
      graph: "",
      is_login: false,
      node_width: 450,
      node_padding_width: 20,
      tipDialogVisible: false,
      y: 0,
      qqDialogVisible: false,
      saveDialogVisible: false,
      project_options: [],
      select_project: "",
      moveDialogVisible: false,
      select_file: "",
      file_options: [],
      tip_text: "",
      is_over3: false,
      e_button: "pubMindmap",
      show_process: false,
      percent: 0,
      all_num: 0,
      zoom: 1,
      scale: 1, // 缩放比例
      startX: 0, // 初始触摸点的X坐标
      startY: 0, // 初始触摸点的Y坐标
      prevDistance: 0, // 上一次触摸点之间的距离
      pinching: false, // 是否正在缩放
      touchIds: [], // 记录触摸点的ID
      prev: 0,
      show_map: true,
      downloading: false,
      water_area: [0, 0, 0, 0],
    };
  },
  computed: {
    logoUrl() {
      return logo_light;
    },
  },
  watch: {
    e_button() {
      this.show_map = this.e_button == "pubMindmap";
      // this.$router.push({
      //   path: `/m/` + this.e_button + `/` + this.$route.params.pathId,
      // });
    },
    select_project() {
      get_filetype_List(this.select_project).then((res) => {
        this.file_options = res;
        this.select_file = "";
      });
    },
  },
  created() {
    this.is_login = getToken() ? true : false;

    let system = {
      win: false,
      mac: false,
      xll: false,
    };
    let p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.x11 = p == "X11" || p.indexOf("Linux") == 0;

    this.hei = window.innerHeight - 120 + "px";
    function dd3(dateString) {
      const targetDate = new Date(dateString);
      const currentDate = new Date();
      const diff = currentDate - targetDate;
      const daysDiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return daysDiff > 3;
    }

    get_status(this.$route.params.pathId).then((data) => {
      let is_read =
        sessionStorage.getItem(data.fileKey) &&
        sessionStorage.getItem(data.fileKey) == "has";

      if (data.effective && data.settingEffective) {
        if (dd3(data.createdAt) && !is_read) {
          this.tip_text = this.$t("share.d3");
          sessionStorage.setItem(data.fileKey, "has");
          this.tipDialogVisible = true;
          this.is_over3 = true;
        }
        getFile(this.$route.params.pathId).then((res) => {
          this.topic = res.meta.name;
          this.all_num = res.data.length;
          if (res.data.length > 50) {
            this.show_process = true;
          }
          setTimeout(() => {
            this.init(res.data);
          }, 1000);
        });
      } else {
        this.show_file = false;
      }
    });
  },
  mounted() {
    document.getElementsByClassName("icon-locale")[0].style.color = "#0042A6";
  },
  methods: {
    add_water_mask() {
      this.water_area[0];

      let bas = this.get_water_base64(this.$store.getters.name);
      let that = this;

      setTimeout(() => {
        let ddd = {
          x: this.water_area[0],
          y: this.water_area[2],
          width: this.water_area[1] - this.water_area[0],
          height: this.water_area[3] - this.water_area[2],
          shape: "html",
          id: "water_mask_share",
          zIndex: 1,
          html() {
            const con = document.createElement("div"); //

            con.style.width = that.water_area[1] - that.water_area[0] + "px";
            con.style.height = that.water_area[3] - that.water_area[2] + "px";
            con.style.backgroundImage = `url(${bas})`;
            con.style.backgroundRepeat = "repeat";

            return con;
          },
        };
        this.graph.addNode(ddd);
      }, 0);
    },
    get_water_base64(text) {
      text = "Mappingspace  " + text;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = this.calculate_node_width_height(text);
      let height = this.new_calculate_node_width_height(text);

      const fontSize = 18;
      const fontFamily = "Arial";
      const textColor = "#e2e2e2";
      const textAlign = "center";
      canvas.width = Math.max(width, height);
      canvas.height = Math.max(width, height) + 100;

      ctx.rotate((25 * Math.PI) / 180);

      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.fillStyle = textColor;
      ctx.textAlign = textAlign;

      ctx.fillText(text, width / 2 + 10, height / 2);

      return canvas.toDataURL("image/png");
    },
    hasAccount() {
      sessionStorage.setItem("exportPng", this.$route.params.pathId);
      this.$router.push({
        path: `/login`,
      });
    },
    noAccount() {
      sessionStorage.setItem("exportPng", this.$route.params.pathId);
      this.$router.push({
        path:
          `/login/register?inviteMark=share-` + `${this.$route.params.pathId}`,
      });
    },
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        this.touchIds = Array.from(event.touches).map(
          (touch) => touch.identifier
        );
        this.pinching = true;
        const [touch1, touch2] = event.touches;
        this.startX = touch1.clientX - touch2.clientX;
        this.startY = touch1.clientY - touch2.clientY;
        this.prevDistance = this.getDistance(touch1, touch2);
        event.preventDefault();
      }
    },
    handleTouchMove(event) {
      if (event.touches.length === 2) {
        if (!this.pinching) return;
        const touches = Array.from(event.touches);
        const touchIds = touches.map((touch) => touch.identifier);
        if (
          touchIds.length !== 2 ||
          !this.touchIds.every((id) => touchIds.includes(id))
        ) {
          // 如果触电不是之前记录的两个，或者中途有手指离开，则结束
          this.handleTouchEnd(event);
          return;
        }
        const [touch1, touch2] = touches;
        const currentDistance = this.getDistance(touch1, touch2);
        const scaleChange = currentDistance / this.prevDistance;
        this.scale *= scaleChange;
        this.graph.zoomTo(this.scale);
        // this.graph.zoomTo(this.scale, { center: { x: 0, y: 0 } });
        this.prevDistance = currentDistance;
        event.preventDefault();
      }
    },
    handleTouchEnd(event) {
      if (event.touches.length == 2) {
        this.pinching = false;
        this.touchIds = [];
      }
      event.preventDefault();
    },
    downloadArrayAsJsFile(array, fileName) {
      const arrayString = `const ressss = ${JSON.stringify(array, null, 2)};\n`;
      const blob = new Blob([arrayString], { type: "text/javascript" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.js`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    to_web() {
      window.open("https://www.ytdevops.com");
    },
    getDistance(touch1, touch2) {
      const dx = touch1.clientX - touch2.clientX;
      const dy = touch1.clientY - touch2.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    },
    customColorMethod(percentage) {
      return "rgb(0,76,170)";
    },
    close_dai() {
      this.saveDialogVisible = false;
      this.select_project = "";
      this.select_file = "";
    },
    notice() {
      if (getToken()) {
        viewRequestNotify(this.$route.params.pathId).then((res) => {
          this.$message({
            message: this.$t("share.send"),
            type: "success",
            duration: "5000",
          });
        });
      } else {
        sessionStorage.setItem("backURL", this.$route.path);
        this.$router.push({
          path: `/login`,
        });
      }
    },
    save_file() {
      if (this.select_project.length < 1) {
        this.$message({
          message: this.$t("share.pro"),
          type: "error",
          duration: "5000",
        });
        return;
      }
      if (this.select_file.length < 1) {
        this.$message({
          message: this.$t("share.file"),
          type: "error",
          duration: "5000",
        });
        return;
      }
      let da = {
        createFileDto: {
          fileTypeId: this.select_file,
          name: this.topic,
          testCase: "",
          testPlanId: "",
        },
        shareId: this.$route.params.pathId,
      };
      save_file(this.select_project, da).then((re) => {
        this.$router.push({
          path: `/${this.select_project}/myMind/${re}`,
        });
      });
    },
    to_down() {
      function isWeChatBrowser() {
        let userAgent = navigator.userAgent.toLowerCase();
        return /micromessenger/.test(userAgent);
      }

      function isQQBrowser() {
        let userAgent = navigator.userAgent.toLowerCase();
        return /mqqbrowser/.test(userAgent);
      }

      if (isWeChatBrowser()) {
        this.qqDialogVisible = true;
        return;
      } else if (isQQBrowser()) {
        this.qqDialogVisible = true;
        return;
      }
      this.tipDialogVisible = false;
      if (getToken()) {
        request_joinedProjects().then((res) => {
          this.project_options = res;
          this.saveDialogVisible = true;
        });
      } else {
        sessionStorage.setItem("backURL", this.$route.path);
        this.moveDialogVisible = true;
      }
    },
    to_login() {
      this.tipDialogVisible = false;
      if (getToken()) {
        request_joinedProjects().then((res) => {
          this.project_options = res;
          this.saveDialogVisible = true;
        });
      } else {
        sessionStorage.setItem("backURL", this.$route.path);
        this.moveDialogVisible = true;
      }
    },
    recordTouchStart(r) {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS && this._isMobile()) {
        console.log("ios");
        r.visible = true; //only work in iOS
      }
    },
    _isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },

    to_word() {
      this.$router.push({
        path: `/m/pubWordview/` + this.$route.params.pathId,
      });
    },
    calculate_node_width_height(text) {
      // 根据文本，计算每个节点的宽
      const canvas = document.getElementById("myCanvas");
      const ctx = canvas.getContext("2d");
      ctx.font = "18px Arial";
      ctx.fillText(text, 0, 0);
      const width = ctx.measureText(text).width;
      return width;
    },
    new_calculate_node_width_height(text) {
      // 根据文本，计算每个节点的高
      const div = document.getElementById("myDiv");
      div.innerText = text;
      return div.clientHeight;
    },
    list_2_tree(list) {
      let map = {},
        node,
        roots = [];
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
      }
      for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentid !== "") {
          list[map[node.parentid]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots[0];
    },
    create_node(tree_node) {
      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        20;
      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          // node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          // node.style.border = "1px solid #428bca";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.textAlign = "left";
          node.style.color = "black";
          node.style.lineHeight = "25px";
          node.innerText = tree_node.topic;

          con.appendChild(node);
          return con;
        },
      };
      return data;
    },
    create_root_node(tree_node) {
      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        50;

      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          // node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "4px solid #428bca";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.textAlign = "left";
          node.style.color = "black";
          node.style.lineHeight = "25px";
          node.innerText =
            tree_node.topic.length > 0 ? tree_node.topic : "new node";

          con.appendChild(node);
          return con;
        },
      };
      return data;
    },
    modify_node_data(obj) {
      if (obj.children && Array.isArray(obj.children)) {
        for (let i in obj.children) {
          obj.children[i] = this.modify_node_data(obj.children[i]);
        }
      }
      if (parseInt(obj.layer) == 1) {
        return this.create_root_node(obj);
      } else {
        return this.create_node(obj);
      }
    },

    create_edge(source_node, target_node) {
      let data = {
        source: source_node, // 起始节点 id
        target: target_node, // 目标节点 id
        markup: [
          {
            tagName: "path",
            selector: "stroke",
          },
        ],
        attrs: {
          stroke: {
            fill: "none",
            stroke: "rgb(66,139,202)",
            connection: true,
            strokeWidth: 2,
            strokeLinecap: "round",
          },
        },

        router: {
          name: "er",
          args: {
            direction: "R",
            offser: "center",
          },
        },
        connector: "rounded",
      };
      return data;
    },
    init_nodes_edges(data) {
      let use_data = JSON.parse(JSON.stringify(data));
      use_data = this.modify_node_data(use_data);
      let result = Hierarchy.mindmap(use_data, {
        direction: "H",
        getHeight(d) {
          return d.height;
        },
        getHGap(d) {
          return 70;
        },
        getVGap() {
          return 20;
        },
        getSide: () => {
          return "right";
        },
      });

      let l = [];
      l.push(result);
      // 循环添加节点
      this.percent = 0;
      let num = 0;

      let min_x1 = 999;
      let min_y1 = 999;
      let max_x1 = -110;
      let max_y1 = -110;

      const updateGraph = () => {
        num++;
        let n = l.shift();
        let d = n.data;
        d.x = n.x + 10;
        d.y = n.y + 10;

        min_x1 = Math.min(min_x1, d.x);
        min_y1 = Math.min(min_y1, d.y);

        max_x1 = Math.max(max_x1, d.x + d.width);
        max_y1 = Math.max(max_y1, d.y + d.height);

        if (this.max_y < d.y) {
          this.max_y = d.y;
          this.max_h = d.height;
        }

        this.graph.addNode(d); // 添加节点

        if (n.parent) {
          this.graph.addEdge(this.create_edge(n.data.id, n.parent.id)); // 添加边
        }
        l = [...l, ...n.children];
        this.percent = (num / this.all_num) * 100;
        if (l.length > 0) {
          setTimeout(updateGraph, 0);
        } else {
          this.water_area = [min_x1, max_x1, min_y1, max_y1];

          this.show_process = false;

          this.graph.zoomTo(0.5);
          this.graph.centerPoint(0, 0); // 视图居中

          let startX,
            startY,
            scrollContainer = document.querySelector(".x6-graph-scroller"),
            scrollPositionX = scrollContainer.scrollLeft,
            scrollPositionY = scrollContainer.scrollTop;

          let that = this;

          scrollContainer.addEventListener("touchstart", function (e) {
            if (e.touches.length === 2) {
              return;
            }
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
          });
          scrollContainer.addEventListener("touchend", function (e) {
            if (e.touches.length == 0) {
              that.pinching = false;
              that.touchIds = [];
            }
          });
          scrollContainer.addEventListener("touchmove", function (e) {
            if (that.pinching) {
              return;
            }
            const deltaX = startX - e.touches[0].clientX;
            const deltaY = startY - e.touches[0].clientY;

            scrollPositionX = scrollContainer.scrollLeft;
            scrollPositionY = scrollContainer.scrollTop;
            scrollPositionX += deltaX;
            scrollPositionY += deltaY;

            scrollContainer.scrollLeft = scrollPositionX;
            scrollContainer.scrollTop = scrollPositionY;

            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
          });

          if (
            this.is_login &&
            sessionStorage.getItem("exportPng") &&
            sessionStorage.getItem("exportPng") == this.$route.params.pathId
          ) {
            setTimeout(() => {
              this.download();
              sessionStorage.removeItem("exportPng");
            }, 1000);
          }
        }
      };
      updateGraph();
    },
    download() {
      this.add_water_mask();
      this.downloading = true;
      let zzz = this.graph.zoom();
      this.graph.zoomTo(1.5);
      let bbb = document.getElementById("node@root").style.border;
      document.getElementById("node@root").style.border = "none";

      this.graph.addNode({
        id: "logo_node",
        shape: "image",
        x: 0,
        y: this.max_y + this.max_h,
        width: 300,
        height: 150,
        imageUrl: get_water(),
      });
      setTimeout(() => {
        try {
          this.graph.toPNG(
            (dataUri) => {
              DataUri.downloadDataUri(dataUri, this.topic + ".png");
              this.graph.zoomTo(zzz);
              document.getElementById("node@root").style.border = bbb;
              setTimeout(() => {
                this.graph.removeNode(`water_mask_share`);
                this.graph.removeNode(`logo_node`);
              }, 0);
            },
            {
              backgroundColor: "white",
              quality: 1,
              padding: {
                top: 20,
                right: 50,
                bottom: 20,
                left: 50,
              },
            }
          );
        } catch (error) {
          alert(error);
        }
      }, 0);
    },
    init(data) {
      this.graph = new Graph({
        // 具体参数见官方文档 https://x6.antv.vision/zh/docs/api/graph/graph
        container: document.getElementById("container"),
        interacting: false,
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"],
        },
        scroller: {
          enabled: true,
        },
        minimap: {
          enabled: true,
          container: document.getElementById("minicontainer"),
          width: window.innerWidth / 3.5,
          height: window.innerWidth / 3.5,
          padding: 0,
        },
      });
      setTimeout(() => {
        let ddd = this.list_2_tree(data);
        this.init_nodes_edges(ddd);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
#loading_share {
  height: calc(100vh);
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  flex-direction: column;
}
#label_container2 {
  // height: calc(100vh - 200px);
}
.mtopic {
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topic {
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.change_button {
  height: 40px;
  // margin-bottom: 10px;
}
.nofile {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 20px;
  }
}
.language-option {
  position: absolute;
  right: 20px;
}
::v-deep .el-radio-button__inner {
  color: black;
  padding: 7px 10vw !important;
  border-color: rgb(0, 76, 170) !important;
}
.save_button {
  height: 40px;
  // position: fixed;
  // bottom: 20px;
  // width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
#myDiv {
  font: 18px Arial; /*no*/
  max-width: 450px; /*no*/
  line-height: 25px; /*no*/
  word-break: break-all;
  position: absolute;
  visibility: hidden;
}
</style>
<style lang="scss">
#minicontainer {
  .x6-widget-minimap {
    width: auto !important;
    height: auto !important;
    border: 2px solid rgb(0, 76, 170);
  }
}
</style>
<style>
.el-scrollbar__bar {
  opacity: 1 !important;
}
</style>
